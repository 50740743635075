import { Avatar } from "@assets";
import useDepartments from "@hooks/departments";
import React from "react";
import { twMerge } from "tailwind-merge";
import employeeCellStyles from "./employee-cell.module.css";
import { ThreeDot } from "react-loading-indicators";
import BoardIcon from "@components/board-icon";

interface EmployeeCellProps {
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  user: Drivn.User;
  removeEmployee: (employeeID: string) => void;
}

const EmployeeCell = ({ selected, setSelected, user, removeEmployee }: EmployeeCellProps) => {
  /**
   * Toggle Selecting The User
   */
  const toggleSelect = () => {
    if (!selected.includes(user.uid)) {
      setSelected([...selected, user.uid]);
    } else {
      const newSelected = [...selected];
      newSelected.splice(newSelected.indexOf(user.uid), 1);
      setSelected(newSelected);
    }
  };

  // The Loading State
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await removeEmployee(user.uid!);
    } finally {
      setLoading(false);
    }
  };

  // The Departments
  const { findCurrentDep } = useDepartments();

  return (
    <tr className={employeeCellStyles.employeeRow}>
      <td className="px-4 py-3 w-4">
        <div className="flex items-center">
          <input
            id="checkbox-table-search-1"
            type="checkbox"
            onClick={toggleSelect}
            className={employeeCellStyles.checkbox}
          />
          <label htmlFor="checkbox-table-search-1" className="sr-only">
            checkbox
          </label>
        </div>
      </td>
      <th scope="row" className={employeeCellStyles.contentColumn}>
        <div className="flex items-center mr-3">
          <img src={user.image ?? Avatar} alt="avatar" className={employeeCellStyles.avatarImage} />
          {`${user.firstName} ${user.lastName}`}
        </div>
      </th>
      <td className="px-4 py-3">
        <div
          data-tooltip-target="tooltip-role-1"
          className={twMerge(
            employeeCellStyles.inlineFlexContainer,
            user.isOwner ? employeeCellStyles.roleBadgeOwner : user.isAdmin && employeeCellStyles.roleBadgeAdmin
          )}
        >
          <BoardIcon/>
          {user.isOwner ? "Owner" : user.isAdmin ? "Administrator" : "Employee"}
        </div>
      </td>
      <td className={employeeCellStyles.emptyColumn}/>
      <td className={employeeCellStyles.contentColumn}>
        {user.departmentId === undefined ? "Hasn't Selected" : findCurrentDep(user.departmentId)?.name ?? ""}
      </td>
      <td className={employeeCellStyles.emptyColumn}/>
      <td className={employeeCellStyles.contentColumn}>
        <div className="flex items-center">
          <div
            className={twMerge(
              "h-3 w-3 rounded-full mr-2",
              user.removed ? employeeCellStyles.statusIndicatorRemoved : employeeCellStyles.statusIndicatorActive
            )}
          />
          {user.removed ? "Removed" : "Active"}
        </div>
      </td>
      <td className={employeeCellStyles.contentColumn}>
        {loading ? (
          <ThreeDot color="#1673ff" size="small" text="" textColor="" />
        ) : (
          <button className={employeeCellStyles.userDelete} onClick={handleDelete}>
            Delete
          </button>
        )}
      </td>
    </tr>
  );
};

export default EmployeeCell;
