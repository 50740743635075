import React from "react";

import { Routes as Switch, Route } from "react-router-dom";
import { Routes } from "../util/routes";

// Error Views
import NotFoundPage from "./error/NotFound";

// No Auth Views
import ForgotPassword from "./no-auth/forgot-password";
import ContactSales from "./no-auth/contact-sales";
import Login from "./no-auth/log-in";
import Home from "./no-auth/home";

// Resources
import Contact from "./resources/contact";
import FAQ from "./resources/faq";

// Legal
import TermsAndConditions from "./legal/TermsAndConditions";
import PrivacyPolicy from "./legal/PrivacyPolicy";

// Dashboard
import Offices from "./dashboard/subviews/offices";
import Settings from "./dashboard/subviews/settings";
import Employees from "./dashboard/subviews/employees";
import Analytics from "./dashboard/subviews/analytics";
import Dashboard from "./dashboard";

// Settings
import OrganizationInfo from "./dashboard/subviews/settings/subviews/organization";
import General from "./dashboard/subviews/settings/subviews/general";
import Billing from "./dashboard/subviews/settings/subviews/billing";
import Departments from "./dashboard/subviews/departments";

/** The Main Page Of The Application */
const Main = () => (
  <Switch>
    {/* No Auth */}
    <Route path={Routes.NotLoggedIn.path} element={Home} />
    <Route path={Routes.Signin.path} element={Login} />
    <Route path={Routes.Contact.path} element={Contact} />
    <Route path={Routes.ForgotPassword.path} element={ForgotPassword} />
    {/* <Route path={Routes.Pricing.path} element={Pricing} /> */}
    {/* <Route path={Routes.Onboarding.path} element={Onboarding} /> */}
    <Route path={Routes.ContactSales.path} element={ContactSales} />
    <Route path={Routes.FAQ.path} element={FAQ} />

    {/* Logged In */}
    <Route path={Routes.Dashboard.path} element={<Dashboard />}>
      <Route index element={<Analytics />} />
      <Route
        path={Routes.Dashboard.Departments.path}
        element={<Departments />}
      />
      <Route path={Routes.Dashboard.Employees.path} element={<Employees />} />
      <Route path={Routes.Dashboard.Offices.path} element={<Offices />} />
      <Route path={Routes.Dashboard.Settings.path} element={<Settings />}>
        <Route index element={<General />} />
        <Route path={Routes.Dashboard.Settings.Organization.path} element={<OrganizationInfo />} />
        <Route path={Routes.Dashboard.Settings.Billing.PaymentInfo.path} element={<Billing />} />
      </Route>
    </Route>

    {/* Legal */}
    <Route
      path={Routes.TermsAndConditions.path}
      element={<TermsAndConditions />}
    />
    <Route path={Routes.PrivacyPolicy.path} element={<PrivacyPolicy />} />

    <Route path="*" element={NotFoundPage} />
  </Switch>
);

// Export Main As Default
export default Main;
