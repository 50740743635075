import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ZonedDateTime } from "@internationalized/date";
import { Id, toast } from "react-toastify";
import { Err } from "samuel-types";
import { boolean } from "yup";

// The InitialState For The Slice
const initialState: {
  orgEntity: Drivn.Analytics | undefined;
  recentAnalytics: Record<string, Drivn.RecentDriveDate>;
  loading: boolean;
  error?: Err;
  start: string;
  end: string;
  alreadyDispatched: boolean;
} = {
  orgEntity: undefined,
  recentAnalytics: {},
  loading: false,
  error: undefined,
  start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
  end: new Date().toISOString(),
  alreadyDispatched: false
};

// The Authentication Redux Slice
const analyticsSlice = createSlice({
  name: "analytics",
  initialState: initialState,
  reducers: {
    setEntity: (state, newValue: PayloadAction<Drivn.Analytics>) => ({
      ...state,
      orgEntity: newValue.payload,
      loading: false,
      error: undefined
    }),
    startAnalyticsUpdate(state) {
      state.loading = true;
      return state;
    },
    analyticsError: (
      state,
      newValue: PayloadAction<[import("samuel-types").Err, Id | undefined]>
    ) => {
      if (newValue.payload[1]) {
        toast.update(newValue.payload[1], {
          render: newValue.payload[0].message,
          type: "error",
          isLoading: false,
          autoClose: 5000
        });
      } else {
        // Toast
        toast.error(newValue.payload[0].message);
      }

      // Setting The State
      return {
        ...state,
        loading: false,
        error: newValue.payload[0],
        alreadyDispatched: true
      };
    },
    setRecent(
      state,
      newValue: PayloadAction<Record<string, Drivn.RecentDriveDate>>
    ) {
      state.recentAnalytics = newValue.payload;
      state.loading = false;
      state.error = undefined;
      state.alreadyDispatched = false;
      return state;
    },
    setDates: (
      state,
      newValue: PayloadAction<{ start: ZonedDateTime; end: ZonedDateTime }>
    ) => {
      state.start = newValue.payload.start.toAbsoluteString();
      state.end = newValue.payload.end.toAbsoluteString();
      return state;
    },
    removeAnalytics: () => ({ ...initialState })
  }
});
// Exporting The Actions
export const {
  setEntity,
  removeAnalytics,
  setRecent,
  analyticsError,
  startAnalyticsUpdate,
  setDates
} = analyticsSlice.actions;

// Exporting The Slice
export default analyticsSlice;
