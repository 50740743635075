import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Id, toast } from "react-toastify";

// The InitialState For The Slice
const initialState: LocalOrganization = {
  organization: undefined,
  loading: false,
  error: undefined
};

// The Authentication Redux Slice
const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrg: (
      state,
      newValue: PayloadAction<Omit<LocalOrganization, "loading" | "error">>
    ) => ({
      ...newValue.payload,
      loading: false,
      error: undefined
    }),
    startOrgUpdate: state => ({ ...state, loading: true }),
    organizationError: (
      state,
      newValue: PayloadAction<[import("samuel-types").Err, Id | undefined]>
    ) => {
      if (newValue.payload[1]) {
        toast.update(newValue.payload[1], {
          render: newValue.payload[0].message,
          type: "error",
          isLoading: false,
          autoClose: 5000
        });
      } else {
        // Toast 
        toast.error(newValue.payload[0].message);
      }

      // Setting The State
      return {
        ...state,
        loading: false,
        error: newValue.payload[0]
      };
    },
    updateOrg: (state, newValue: PayloadAction<Partial<Drivn.Organization>>) => ({
      organization: merge(state.organization, newValue.payload),
      loading: false,
      error: undefined
    }),
    removeOrg: () => ({ ...initialState })
  }
});
// Exporting The Actions
export const { setOrg, removeOrg, updateOrg, startOrgUpdate, organizationError } =
  organizationSlice.actions;

// Exporting The Slice
export default organizationSlice;

const merge = <T>(value1: T, value2: Partial<T>): T => ({
  ...value1,
  ...value2
});
