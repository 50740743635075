import { useState, useRef } from "react";
/* eslint-disable no-console */
// Helpers
import {
  useAppDispatch,
  officeError,
} from "@util";
import { useApi } from "@hooks/api";

const useTrips = () => {
  const [loading, setLoading] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  
  // The API
  const api = useApi();

  // Create an AbortController instance
  abortControllerRef.current = new AbortController();

  // The App Dispatch
  useAppDispatch();

  

  /**
   * Fetch Offices
   */
  const fetchTrips = (betweenDays?: string) => {
    setLoading(true);
    
    // Dead: dispatch(startOfficeUpdate());

    // Fetch The Offices
    api
      .requestWithJSON<Drivn.TripResponse>(
        `/organization/trips/?between-days="${betweenDays}"`,
        true,
        'GET',
      )
      /* Dead: .then(([, res]) => {
         // Handling Errors
         if (res instanceof Err) {
           // dispatch(officeError([res, undefined]));
           return;
         }

         // dispatch(addOffices([filteredOffices, first]));
       }) */
      .catch(err => api.handleErrors(err, officeError));
    setLoading(false);
  };

  const cancelFetchTrips = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };


  return {
    fetchTrips,
    cancelFetchTrips,
    loading,
  };
};

// Exporting The Hook
export default useTrips;
