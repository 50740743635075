import React from "react";

import { useDarkMode } from "@hooks/dark-mode";
import Chart from "react-apexcharts";
import useAnalytics from "@hooks/analytics";

const TotalCO2Emissions: React.FC = function () {
  const analytics = useAnalytics();

  let totalDistance = 0;
  for (const val of Object.values(analytics.recentAnalytics)) {
    totalDistance += val.totalDistance;
  }

  return (
    <div className="rounded-lg bg-secBg p-4 shadow sm:p-6 xl:p-8">
      <div className="flex items-center">
        <div className="shrink-0">
          <span className="text-2xl font-bold leading-none text-black sm:text-3xl">
            {totalDistance.toFixed(2)} km
          </span>
          <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
          Distance travelled
          </h3>
        </div>
      </div>
      <TotalCO2EmissionsChart />
    </div>
  );
};

const TotalCO2EmissionsChart: React.FC = function () {
  const dark = useDarkMode();

  const analytics = useAnalytics();

  const fillGradientShade = dark.dark ? "dark" : "light";
  const fillGradientShadeIntensity = dark.dark ? 0.45 : 1;

  const options: ApexCharts.ApexOptions = {
    labels: Object.keys(analytics.recentAnalytics),
    chart: {
      fontFamily: "Inter, sans-serif",
      sparkline: {
        enabled: true
      },
      toolbar: {
        show: true
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: fillGradientShade,
        shadeIntensity: fillGradientShadeIntensity
      }
    },
    plotOptions: {
      area: {
        fillTo: "end"
      }
    },
    theme: {
      monochrome: {
        enabled: true,
        color: dark.primary
      }
    },
    legend: {
      show: true
    },
    tooltip: {
      hideEmptySeries: false,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif"
      },
      y: {
        formatter: val => `${val.toFixed(2)}km`
      }
    }
  };
  const series = [
    {
      name: "Distance",
      data: Object.values(analytics.recentAnalytics).map(
        val => val.totalDistance
      )
    }
  ];

  return <Chart height={305} options={options} series={series} type="area" />;
};

export default TotalCO2Emissions;
