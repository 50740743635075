import React from "react";

// Helpers
import { Slide, ToastContainer } from "react-toastify";
import { useDarkMode } from "@hooks/dark-mode";

const ConfigureToast = () => {
  // Dark Mode
  const { dark } = useDarkMode();

  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={dark ? "dark" : "colored"}
      transition={Slide}
    />
  );
};

export default ConfigureToast;
