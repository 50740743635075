/* eslint-disable no-console */
import { useCallback, useEffect } from "react";

// Helpers
import { useApi } from "@hooks/api";
import {
  useAppDispatch,
  useAppSelector,
  Err,
  analyticsError,
  startAnalyticsUpdate,
  setRecent,
  Type
} from "@util";

/**
 * Necessary Type Guard because TypeScript is not smart enough
 */
const isErr = (value: unknown): value is Err => {
  return value instanceof Err;
};

/**
 * Handle Analytics State
 */
const useAnalytics = () => {
  // The Current Analytics
  const { recentAnalytics, orgEntity, loading, error, start, end } =
    useAppSelector(({ analytics }) => analytics);

  // App Dispatch
  const dispatch = useAppDispatch();

  // The API
  const api = useApi();

  // Fetching The Analytics By Entity
  const fetchEntity = async (depId: string): Promise<Drivn.Analytics | undefined> => {
    try {
      const [, res] = await api.requestWithJSON<Drivn.Analytics>(`/analytics/summary/${depId}`, true);

      // If it's an error, return early:
      if (isErr(res)) {
        dispatch(analyticsError([res, undefined]));
        return undefined;
      }

      return {
        id: res.id,
        totalTrips: res.totalTrips,
        totalEmissions: res.totalEmissions,
        totalDistance: res.totalDistance,
        activityTypes: res.activityTypes,
        recentTripAnalyticsIds: res.pastTripAnalyticsIds,
        pastTripAnalyticsIds: res.pastTripAnalyticsIds,
      };
  
    } catch (err) {
      api.handleErrors(err, analyticsError);
      return undefined;
    }
  };
  

  // Fetching The Analytics By Entity
  const fetchRecent = useCallback(
    (start: string, end: string) => {
      // Setting Loading
      dispatch(startAnalyticsUpdate());

      api
        .requestWithJSON<Record<string, Drivn.RecentDriveDate>>(
          `/analytics/org?start=${start}&end=${end}`,
          true
        )
        .then(([, res]) => {
          if (error?.message === "Empty response") {
            console.error("Empty response from /analytics/org");
            return;
          }

          if (isErr(res)) {
            dispatch(analyticsError([res, undefined]));
            return;
          }

          if (Object.keys(res).length === 0) {
            dispatch(analyticsError([
              new Err({ name: Type.UNKOWN, message: "Empty response", relatedVar: "res" }),
              undefined
            ]));
            return;
          }

          // Setting The Analytics
          dispatch(setRecent(res));
        })
        .catch(err => api.handleErrors(err, analyticsError));
    },
    [api, dispatch]
  );

  useEffect(() => {
    // If The Analytics Was Already Fetched, End Here
    if (loading || error !== undefined || Object.values(recentAnalytics).length > 0 || orgEntity) {
      return;
    }

    // Try to fetch the Entity
    fetchRecent(start, end);
  }, [
    dispatch,
    error,
    loading,
    fetchRecent,
    orgEntity,
    recentAnalytics,
    start,
    end
  ]);

  return {
    entity: orgEntity,
    recentAnalytics: recentAnalytics,
    formattedStart: new Date(start).toISOString().split("T")[0],
    formattedEnd: new Date(end).toISOString().split("T")[0],
    loading,
    error,
    start,
    end,
    fetchRecent,
    fetchEntity
  };
};

// Exporting The Analytics
export default useAnalytics;
