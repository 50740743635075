import React from "react";

// Helpers
import useDepartments from "@hooks/departments";
import { updateTempDepartment, useAppDispatch } from "@util";
import { hsvaToHex, getContrastingColor } from "@uiw/color-convert";
import Swatch from "@uiw/react-color-swatch";
import { twMerge } from "tailwind-merge";

export const chartColors: [string, string][] = [
  ["#FF6384", "Vivid Pink"],
  ["#36A2EB", "Bright Blue"],
  ["#FFCE56", "Sunny Yellow"],
  ["#4BC0C0", "Teal"],
  ["#9966FF", "Soft Purple"],
  ["#FF9F40", "Orange Peel"],
  ["#FFCD56", "Canary Yellow"],
  ["#4DB8FF", "Sky Blue"],
  ["#36A2EB", "Cerulean"],
  ["#C9CB3C", "Pear"],
  ["#FF6384", "Watermelon"],
  ["#99FF99", "Mint Green"],
  ["#66FF66", "Screamin Green"],
  ["#FF6666", "Soft Red"],
  ["#3399FF", "Dodger Blue"],
  ["#CCCCFF", "Lavender Blue"],
  ["#99CC00", "Apple Green"],
  ["#FF9966", "Peach Orange"],
  ["#6666FF", "Han Purple"],
  ["#CC99FF", "Light Pastel Purple"],
  ["#FFCC99", "Peach"],
  ["#3366CC", "Blue Ribbon"],
  ["#99CCCC", "Powder Blue"],
  ["#FFCCCC", "Pastel Red"],
  ["#CCFFCC", "Tea Green"],
  ["#CCCCCC", "Silver"],
  ["#FF99CC", "Pink Flamingo"],
  ["#FFCC00", "Tangerine"],
  ["#CCFF99", "Pale Lime Green"],
  ["#99CCFF", "Light Cornflower Blue"]
  // Placeholder for additional colors
];

const DepartmentDrawer = () => {
  // App Dispatch
  const dispatch = useAppDispatch();

  // The Department
  const dep = useDepartments();

  const Point = (props: { color?: string; checked?: boolean }) => {
    if (!props.checked) {
      return null;
    }
    return (
      <div
        style={{
          height: 5,
          width: 5,
          borderRadius: "50%",
          backgroundColor: getContrastingColor(props.color!)
        }}
      />
    );
  };

  return (
    <div
      id="createProductModal"
      className={twMerge(
        "overflow-y-auto overflow-x-hidden fixed z-50 w-full md:inset-0 bg-[#000] bg-opacity-30 max-h-full transition-all",
        dep.tempDepartment ? "" : "opacity-0 pointer-events-none"
      )}
    >
      <div className="flex justify-center items-center h-full">
        <div className="relative m-auto p-4 w-full max-w-2xl max-h-full">
          {/* Modal content */}
          <div className="relative p-4 bg-bg rounded-lg shadow sm:p-5">
            {/* Modal header */}
            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
              <h3 className="text-lg font-semibold text-black">
                {dep.tempDepartment?.id === undefined ? "Add" : "Update"}{" "}
                Department
              </h3>
              <button
                onClick={() => dispatch(updateTempDepartment(undefined))}
                type="button"
                className="text-gray-400 transition-all bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-main hover:text-bg"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <form
              onSubmit={
                dep.tempDepartment?.id === undefined
                  ? dep.addDepartment
                  : dep.updateDepartment
              }
            >
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-black"
                  >
                    Department Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="bg-secBg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Department name"
                    required
                    value={dep.tempDepartment?.name ?? ""}
                    onChange={eve =>
                      dispatch(
                        updateTempDepartment({
                          ...dep.tempDepartment!,
                          name: eve.target.value
                        })
                      )
                    }
                  />
                </div>
                <div>
                  <label
                    htmlFor="price"
                    className="block mb-2 text-sm font-medium text-black"
                  >
                    Employee Count
                  </label>
                  <input
                    type="number"
                    name="price"
                    id="price"
                    className="bg-secBg border border-gray-300 text-black text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Employee Count"
                    required
                    value={dep.tempDepartment?.employeeCount ?? 0}
                    onChange={({ target }) =>
                      dispatch(
                        updateTempDepartment({
                          ...dep.tempDepartment!,
                          employeeCount: parseInt(target.value)
                        })
                      )
                    }
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-black">
                    Department Color (For Analytics)
                  </label>
                  <Swatch
                    colors={chartColors.map(col => col[0])}
                    color={dep.tempDepartment?.color ?? "#000"}
                    rectProps={{
                      children: <Point />,
                      style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }
                    }}
                    onChange={hsvColor => {
                      dispatch(
                        updateTempDepartment({
                          ...dep.tempDepartment!,
                          color: hsvaToHex(hsvColor)
                        })
                      );
                    }}
                  />
                  {/* <input
                    value={offices.tempOffice.color}
                    onChange={event =>
                      dispatch(
                        updateTempOffice({
                          ...offices.tempOffice!,
                          color: event.target.value
                        })
                      )
                    }
                    type="color"
                    className="bg-secBg h-[62px] border border-gray-300 cursor-pointer text-black text-sm rounded-full focus:ring-primary-500 focus:border-primary-500 block w-full dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required={true}
                  /> */}
                </div>
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      checked={dep.tempDepartment?.travelsForBusiness ?? false}
                      onChange={eve =>
                        dispatch(
                          updateTempDepartment({
                            ...dep.tempDepartment!,
                            travelsForBusiness: eve.currentTarget.checked
                          })
                        )
                      }
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label className="text-gray-500 dark:text-gray-300">
                      Travels for business
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="text-main ml-auto bg-secBg hover:text-bg hover:bg-main transition-all inline-flex items-center focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-800"
                >
                  <svg
                    className="mr-1 -ml-1 w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {dep.tempDepartment?.id === undefined ? "Add" : "Update"}{" "}
                  Department
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentDrawer;
