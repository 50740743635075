import React from "react";

// Helpers
import { updateTempDepartment, useAppDispatch } from "@util";
import { ThreeDot } from "react-loading-indicators";

// An Department Cell
const DepartmentCell = ({ department, employees, analytics, removeDepartment }: DepartmentCellProps) => {
  // App Dispatch
  const dispatch = useAppDispatch();

  // The Loading State
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await removeDepartment(department.id!);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <tr
      className="border-b dark:border-gray-700 hover:bg-bg transition-all"
      onClick={() => dispatch(updateTempDepartment(department))}
    >
      <th
        scope="row"
        className="px-4 py-3 font-medium text-black whitespace-nowrap"
      >
        {department.name}
      </th>
      <td className="px-4 py-3">{department.employeeCount}</td>
      <td className="px-4 py-3">{employees.length}</td>
      <td className="px-4 py-3 max-w-[12rem] truncate">
        {Math.round(analytics.totalEmissions)} kg CO₂
      </td>
      <td className="px-4 py-3">
        {employees.length === 0 ? 0 : Math.round(analytics.totalEmissions/employees.length)} kg CO₂
      </td>
      <td className="px-4 py-3">
        {loading ? (
          <ThreeDot color="#1673ff" size="small" text="" textColor="" />
        ) : (
          <button className="py-2 px-5 bg-red-600 text-white font-semibold rounded-full shadow-md hover:bg-red-700 focus:outline-none focus:ring focus:ring-violet-400 focus:ring-opacity-75;" onClick={(event) => {
            event.stopPropagation();
            handleDelete();
          }}>
            Delete
          </button>
        )}
      </td>
    </tr>
  );
};

export default DepartmentCell;
