import React, { useEffect, useState, useCallback } from "react";

// Hooks
import { setDocumentTitle } from "@hooks/document-title";
import { useDarkMode } from "@hooks/dark-mode";
import { parseAbsoluteToLocal } from "@internationalized/date";
import { DateRangePicker, RangeValue } from "@nextui-org/react";
import { useAppDispatch, setDates } from "@util";
import useAnalytics from "@hooks/analytics";
import useOrganization from "@hooks/organization";

// Components/Charts
import DateRangeHelper from "util/services/DateRangeHelper";
import EmissionsByActivityType from "@components/charts/pie-chart";
import TotalCO2Emissions from "@components/charts/med-line-chart";
import BigEmissionComponent from "@components/charts/big-emissions-component";
import CommuteChartComponent from "@components/charts/commute-chart";
import { Slab } from "react-loading-indicators";
import { DateValue } from "@react-types/datepicker";
import "./analytics.tsx.css";

/**
 * Displays a loading state if `loading === true`.
 */
const AnalyticsLoadingView: React.FC<{ loading: boolean }> = ({ loading }) => {
  const dark = useDarkMode();

  if (!loading) 
  {
    return null;
  }

  return (
    <div className="w-full min-h-[calc(100vh-110px)] mt-[110px] flex justify-center items-center">
      <Slab
        color={dark.dark ? "#80abf9" : "#1673ff"}
        size="large"
        text="Loading Analytics..."
        textColor={dark.dark ? "#fff" : "#000"}
      />
    </div>
  );
};

/**
 * Component for selecting a time range (dropdown + DateRangePicker).
 */
const DateRangeControls: React.FC<{
  selectedOption: string;
  onOptionChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedRange: RangeValue<DateValue>;
  onDateRangeChange: (val: RangeValue<DateValue> | null) => void;
  orgName: string;
}> = ({ selectedOption, onOptionChange, selectedRange, onDateRangeChange, orgName }) => {
  return (
    <div className="range-control-header">
      <div className="org-name">
        <h1 className="text-2xl font-bold">{orgName}</h1>
      </div>
      
      <div className="range-controls">
        <div>
          <select
            value={selectedOption}
            onChange={onOptionChange}
            className="bg-white text-black border px-2 py-1 border-black rounded"
            style={{ width: "200px" }}
          >
            <option value="">Select Time Range</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
            <option value="year">This Year</option>
          </select>
        </div>

        <div>
          <DateRangePicker
            label="Stay duration"
            visibleMonths={2}
            value={selectedRange}
            onChange={onDateRangeChange}
            hideTimeZone={true}
            style={{ width: "200px" }}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Displays the main Analytics content when NOT loading.
 */
const AnalyticsContent: React.FC<{
  selectedOption: string;
  selectedRange: RangeValue<DateValue>;
  org: ReturnType<typeof useOrganization>;
}> = ({ selectedOption, selectedRange, org }) => {
  const analytics = useAnalytics();

  return (
    <div className="px-4">
      {/* Big Emission Chart */}
      <BigEmissionComponent selectedOption={selectedOption} selectedRange={selectedRange} />

      {/* Grid with 3 smaller charts */}
      <div className="mt-4 grid w-full grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
        <TotalCO2Emissions />
        <CommuteChartComponent analytics={analytics} />
        <EmissionsByActivityType />
      </div>
    </div>
  );
};

// -----------------------------------------------------------------------------
// MAIN COMPONENT: ANALYTICS
// -----------------------------------------------------------------------------

const Analytics: React.FC = () => {
  const dispatch = useAppDispatch();
  const analytics = useAnalytics();
  const org = useOrganization();

  useEffect(() => {
    setDocumentTitle("Analytics");
  }, []);

  // Local state for time interval
  const [selectedRange, setSelectedRange] = useState<RangeValue<DateValue>>(
    DateRangeHelper.getDefaultDateRange()
  );
  // Chosen dropdown setting (week, month, year)
  const [selectedOption, setSelectedOption] = useState("");

  /**
   * When the user changes the time range via the dropdown.
   */
  const handleOptionChange = useCallback(
    (en: React.ChangeEvent<HTMLSelectElement>) => {
      const value = en.target.value;
      setSelectedOption(value);

      const { start, end } = DateRangeHelper.getRangeByOption(value);
      setSelectedRange({ start, end });

      dispatch(setDates({ start, end }));
      analytics.fetchRecent(start.toAbsoluteString(), end.toAbsoluteString());
    },
    [dispatch, analytics]
  );

  /**
   * When the user changes the date via the DateRangePicker.
   */
  const handleDateChange = useCallback(
    (range: RangeValue<DateValue> | null) => {
      if (!range?.start || !range.end) 
      {
        return;
      }

      const startDate = new Date(range.start.year, range.start.month - 1, range.start.day);
      const endDate = new Date(range.end.year, range.end.month - 1, range.end.day);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      const start = parseAbsoluteToLocal(startDate.toISOString());
      const end = parseAbsoluteToLocal(endDate.toISOString());

      setSelectedRange({ start, end });
      dispatch(setDates({ start, end }));
      analytics.fetchRecent(start.toAbsoluteString(), end.toAbsoluteString());
    },
    [dispatch, analytics]
  );

  return (
    <div className="analytics-container w-full">
      <AnalyticsLoadingView loading={analytics.loading} />

      {/* Only show main content if NOT loading */}
      {!analytics.loading && (
        <>
          <DateRangeControls
            selectedOption={selectedOption}
            onOptionChange={handleOptionChange}
            selectedRange={selectedRange}
            onDateRangeChange={handleDateChange}
            orgName={org.organization?.name || ""}
          />
          <AnalyticsContent
            selectedOption={selectedOption}
            selectedRange={selectedRange}
            org={org}
          />
        </>
      )}
    </div>
  );
};

export default Analytics;
