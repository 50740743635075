import React, { useRef, useState } from "react";

import { Breadcrumb, Card } from "flowbite-react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { HiCloudUpload, HiHome } from "react-icons/hi";
import { FaGoogle, FaApple } from "react-icons/fa";
import { HiMiniXMark } from "react-icons/hi2";
import { Avatar } from "@assets";

// Helpers
import useDepartments from "@hooks/departments";
import useOffices from "@hooks/offices";
import { useUser } from "@hooks/user";
import { Routes } from "@util";

const General: React.FC = function () {
  return (
    <>
      <div className="grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-bg xl:grid-cols-3 xl:gap-4">
        <div className="col-span-full">
          <Breadcrumb className="mb-4">
            <Breadcrumb.Item href={Routes.Dashboard.Analytics.absolutePath}>
              <div className="flex items-center gap-x-3 group">
                <HiHome className="text-xl text-gray-500 transition-all group-hover:!text-main" />
                <span className="text-black group-hover:!text-main transition-all">
                  Dashboard
                </span>
              </div>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Settings</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="text-xl font-semibold text-black sm:text-2xl">
            User settings
          </h1>
        </div>
        <div className="col-span-full xl:col-auto">
          <div className="grid grid-cols-1 gap-y-4">
            <UserProfileCard />
            <SocialAccountsCard />
          </div>
        </div>
        <div className="col-span-2">
          <div className="grid grid-cols-1 gap-y-4">
            <GeneralInformationCard />
            <PasswordInformationCard />
          </div>
        </div>
      </div>
      {/* <div className="grid grid-cols-1 gap-y-6 px-4 pt-4 xl:grid-cols-2 xl:gap-4 mb-10">
        <AlertsNotificationsCard />
        <EmailNotificationsCard />
      </div> */}
    </>
  );
};

const UserProfileCard: React.FC = () => {
  // The User
  const user = useUser();

  // The File Input Button
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  // The Temporary Image
  const [[tempFileUrl, tempFile], setTempFile] = useState<
    [string | undefined, File | undefined]
  >([undefined, undefined]);

  /**
   * Save A Temproary Image To Preview
   *
   * @param event - The React Event Handler
   */
  const setTempImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempFile([
      URL.createObjectURL(event.target.files![0]),
      event.target.files![0]
    ]);
  };

  /**
   * Convert A File To Base 64 Format
   *
   * @param file - The File To Convert To Base 64
   * @returns The Base 64 String
   */
  const getBase64 = (file: File): Promise<string> =>
    new Promise(resolve => {
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // On reader load somthing...
      reader.onload = () => resolve(reader.result as string);
    });

  const updateImage = async (file: File) => {
    // The Base 64 Image
    const base64 = await getBase64(file);

    // The User Update Promise
    await user.updateUser({ image: base64 });

    // Removing The Image
    setTempFile([undefined, undefined]);
  };

  return (
    <Card className="!bg-secBg">
      <div className="items-center sm:flex sm:space-x-4 xl:block xl:space-x-0 2xl:flex 2xl:space-x-4">
        <img
          width={100}
          height={100}
          src={tempFileUrl ?? user.user?.image ?? Avatar}
          alt="avatar"
          className="rounded-full"
        />
        <div>
          <h3 className="mb-1 text-2xl font-bold text-black mt-2">
            {`${user.user?.firstName} ${user.user?.lastName}`}
          </h3>
          <div className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
            {user.user?.isOwner === true
              ? "Organization Owner"
              : user.user?.isAdmin === true
              ? "Organization Administrator"
              : "Organization Employee"}
          </div>
          <>
            {tempFile === undefined ? (
              <button
                onClick={() => hiddenFileInput.current!.click()}
                disabled={user.loading}
                className="inline-flex border border-gray-500 items-center rounded-lg px-3 py-2 text-center text-sm font-medium opacity-50 enabled:opacity-100 enabled:hover:text-bg enabled:hover:bg-main focus:ring-4 focus:ring-bg text-black transition-all"
              >
                <HiCloudUpload className="mr-2" />
                Change picture
              </button>
            ) : (
              <div className="flex justify-between">
                <button
                  onClick={() => setTempFile([undefined, undefined])}
                  className="inline-flex border border-red-500 items-center rounded-lg px-3 py-2 text-center text-sm font-medium opacity-50 enabled:opacity-100 enabled:hover:text-[#FFF] enabled:hover:bg-red-500 focus:ring-4 focus:ring-bg text-black transition-all"
                >
                  <HiMiniXMark className="mr-2" />
                  Discard
                </button>
                <button
                  onClick={() => updateImage(tempFile)}
                  disabled={user.loading}
                  className="inline-flex border border-gray-500 items-center rounded-lg px-3 py-2 text-center text-sm font-medium opacity-50 enabled:opacity-100 enabled:hover:text-bg enabled:hover:bg-main focus:ring-4 focus:ring-bg text-black transition-all"
                >
                  <IoMdCheckmarkCircleOutline className="mr-2" />
                  Save Changes
                </button>
              </div>
            )}
            <input
              onChange={setTempImage}
              accept="image/png, image/jpeg"
              type="file"
              ref={hiddenFileInput}
              className="hidden"
            />
          </>
        </div>
      </div>
    </Card>
  );
};

const SocialAccountsCard: React.FC = function () {
  return (
    <Card className="!bg-secBg">
      <div className="flow-root">
        <h3 className="text-xl font-bold text-black">Connected accounts</h3>
        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
          <li className="py-4">
            <div className="flex items-center space-x-4">
              <div className="shrink-0">
                <FaApple className="text-xl text-black" />
              </div>
              <div className="min-w-0 flex-1">
                <span className="block truncate text-base font-semibold text-black">
                  Apple account
                </span>
              </div>
              <div className="inline-flex items-center">
                <button className="px-3 py-2 ml-auto rounded-lg opacity-60 border border-gray-500 cursor-not-allowed transition-all text-black">
                  Coming soon
                </button>
              </div>
            </div>
          </li>
          <li className="py-4">
            <div className="flex items-center space-x-4">
              <div className="shrink-0">
                <FaGoogle className="text-xl text-black" />
              </div>
              <div className="min-w-0 flex-1">
                <span className="block truncate text-base font-semibold text-black">
                  Google account
                </span>
              </div>
              <div className="inline-flex items-center">
                <button className="px-3 py-2 ml-auto rounded-lg opacity-60 border border-gray-500 cursor-not-allowed transition-all text-black">
                  Coming soon
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Card>
  );
};

const GeneralInformationCard: React.FC = function () {
  // The User Object
  const user = useUser();

  // The Temporary User Object
  const [tempUser, setTempUser] = useState<Drivn.User | undefined>(user.user);

  // All Offices
  const { offices } = useOffices();

  // The Departments
  const { departments } = useDepartments();

  return (
    <Card className="!bg-secBg">
      <h3 className="mb-4 text-xl font-bold text-black">General information</h3>
      <form action="#">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
            <div>
              <label className="block mb-2 text-sm font-medium text-black">
                First Name
              </label>
              <input
                type="text"
                name="first-name"
                id="first-name"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="John"
                required={false}
                value={tempUser?.firstName ?? ""}
                onChange={event =>
                  setTempUser({ ...tempUser!, firstName: event.target.value })
                }
              />
            </div>
          </div>
          <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
            <div>
              <label className="block mb-2 text-sm font-medium text-black">
                Last Name
              </label>
              <input
                type="text"
                name="last-name"
                id="last-name"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="Doe"
                required={false}
                value={tempUser?.lastName ?? ""}
                onChange={event =>
                  setTempUser({ ...tempUser!, lastName: event.target.value })
                }
              />
            </div>
          </div>
          <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
            <div>
              <label className="block mb-2 text-sm font-medium text-black">
                Office
              </label>
              <select
                id="office"
                value={tempUser?.officeId ?? user.user?.officeId ?? ""}
                onChange={eve => {
                  setTempUser({
                    ...tempUser!,
                    officeId: eve.target.value
                  });
                }}
                className="bg-secBg pr-2 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option>Select Office</option>
                {offices.map(({ offices }) =>
                  offices.map(office => (
                    <option value={office.id} key={office.id}>
                      {office.name}
                    </option>
                  ))
                )}
              </select>
            </div>
          </div>
          <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
            <div>
              <label className="block mb-2 text-sm font-medium text-black">
                Department
              </label>
              <select
                id="department"
                value={tempUser?.departmentId ?? user.user?.departmentId ?? ""}
                onChange={eve => {
                  setTempUser({
                    ...tempUser!,
                    departmentId: eve.target.value
                  });
                }}
                className="bg-secBg pr-2 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option>Select Department</option>
                {departments.map(({ departments }) =>
                  departments.map(dep => (
                    <option value={dep.id} key={dep.id}>
                      {dep.name}
                    </option>
                  ))
                )}
              </select>
            </div>
          </div>
          <div className="col-span-6 ml-auto">
            <button
              disabled={user.loading}
              onClick={() => tempUser && user.updateUser(tempUser)}
              className="px-3 py-2 bg-secBg border border-gray-300 hover:border-main dark:border-gray-500 rounded-lg opacity-50 enabled:opacity-100 enabled:hover:bg-main transition-all enabled:hover:text-bg"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </Card>
  );
};

const PasswordInformationCard: React.FC = () => {
  // The User
  const user = useUser();

  return (
    <Card className="!bg-secBg mb-20">
      <h3 className="mb-4 text-xl font-bold text-black">
        Password information
      </h3>
      <form action="#">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
            <div>
              <label className="block mb-2 text-sm font-medium text-black">
                Current Password
              </label>
              <input
                type="password"
                name="current-password"
                id="current-password"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="••••••••"
                required={false}
              />
            </div>
          </div>
          <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
            <div>
              <label className="block mb-2 text-sm font-medium text-black">
                New Password
              </label>
              <input
                type="password"
                name="new-password"
                id="new-password"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="••••••••"
                required={false}
              />
            </div>
          </div>
          <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
            <div>
              <label className="block mb-2 text-sm font-medium text-black">
                Confirm Password
              </label>
              <input
                type="password"
                name="confirm-password"
                id="confirm-password"
                className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                placeholder="••••••••"
                required={false}
              />
            </div>
          </div>
          <div className="col-span-full">
            <div className="mb-1 text-sm font-normal text-gray-500 dark:text-gray-400">
              Password requirements:
            </div>
            <ul className="space-y-1 pl-4 text-gray-500 dark:text-gray-400">
              <li className="text-xs font-normal">
                At least 10 characters (and up to 100 characters)
              </li>
              <li className="text-xs font-normal">
                At least one lowercase character
              </li>
              <li className="text-xs font-normal">
                Inclusion of at least one special character, e.g., ! @ # ?
              </li>
            </ul>
          </div>
          <div className="col-span-6 flex ml-auto mt-6">
            <button
              disabled={user.loading}
              className="px-3 ml-auto py-2 bg-secBg border border-gray-500 rounded-lg opacity-50 enabled:opacity-100 enabled:hover:bg-main transition-all enabled:hover:text-bg"
            >
              Save All
            </button>
          </div>
        </div>
      </form>
    </Card>
  );
};

/** A
 * const AlertsNotificationsCard: React.FC = function () {
  const [isCompanyNews, setCompanyNews] = useState(true);
  const [isAccountActivity, setAccountActivity] = useState(true);
  const [isMeetupsNearYou, setMeetupsNearYou] = useState(true);
  const [isNewMessages, setNewMessages] = useState(false);

  return (
    <Card className="!bg-secBg">
      <div className="flow-root">
        <h3 className="text-xl font-bold text-black">
          Alerts &amp; Notifications
        </h3>
        <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
          You can set up Themesberg to get notifications
        </p>
        <div className="divide-y divide-gray-200 dark:divide-gray-700">
          <div className="flex items-center justify-between py-4">
            <div className="flex grow flex-col">
              <div className="text-lg font-semibold text-black">
                Company News
              </div>
              <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                Get Themesberg news, announcements, and product updates
              </div>
            </div>
            <Label htmlFor="company-news" className="sr-only">
              Toggle company news
            </Label>
            <ToggleSwitch
              checked={isCompanyNews}
              id="company-news"
              label=""
              name="company-news"
              onChange={() => setCompanyNews(!isCompanyNews)}
            />
          </div>
          <div className="flex items-center justify-between py-4">
            <div className="flex grow flex-col">
              <div className="text-lg font-semibold text-black">
                Account Activity
              </div>
              <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                Get important notifications about you or activity you've missed
              </div>
            </div>
            <Label htmlFor="account-activity" className="sr-only">
              Toggle account activity
            </Label>
            <ToggleSwitch
              checked={isAccountActivity}
              id="account-activity"
              label=""
              name="account-activity"
              onChange={() => setAccountActivity(!isAccountActivity)}
            />
          </div>
          <div className="flex items-center justify-between py-4">
            <div className="flex grow flex-col">
              <div className="text-lg font-semibold text-black">
                Meetups Near You
              </div>
              <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                Get an email when a Dribbble Meetup is posted close to my
                location
              </div>
            </div>
            <Label htmlFor="meetups-near-you" className="sr-only">
              Toggle meetups near you
            </Label>
            <ToggleSwitch
              checked={isMeetupsNearYou}
              id="meetups-near-you"
              label=""
              name="meetups-near-you"
              onChange={() => setMeetupsNearYou(!isMeetupsNearYou)}
            />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div className="flex grow flex-col">
              <div className="text-lg font-semibold text-black">
                New Messages
              </div>
              <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                Get Themsberg news, announcements, and product updates
              </div>
            </div>
            <Label htmlFor="new-messages" className="sr-only">
              Toggle new messages
            </Label>
            <ToggleSwitch
              checked={isNewMessages}
              id="new-messages"
              label=""
              name="new-messages"
              onChange={() => setNewMessages(!isNewMessages)}
            />
          </div>
        </div>
        <div className="flex ml-auto mt-6">
          <button className="px-3 ml-auto py-2 bg-secBg border border-gray-500 rounded-lg hover:bg-main transition-all hover:text-bg">
            Save All
          </button>
        </div>
      </div>
    </Card>
  );
};

const EmailNotificationsCard: React.FC = function () {
  const [isRatingReminders, setRatingReminders] = useState(false);
  const [isItemUpdateNotifications, setItemUpdateNotifications] =
    useState(true);
  const [isItemCommentNotifications, setItemCommentNotifications] =
    useState(true);
  const [isBuyerReviewNotifications, setBuyerReviewNotifications] =
    useState(true);

  return (
    <Card className="!bg-secBg">
      <div className="flow-root">
        <h3 className="text-xl font-bold text-black">Email Notifications</h3>
        <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
          You can set up Themesberg to get email notifications
        </p>
        <div className="divide-y divide-gray-200 dark:divide-gray-700">
          <div className="flex items-center justify-between py-4">
            <div className="flex grow flex-col">
              <div className="text-lg font-semibold text-black">
                Rating reminders
              </div>
              <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                Send an email reminding me to rate an item a week after purchase
              </div>
            </div>
            <Label htmlFor="rating-reminders" className="sr-only">
              Toggle rating reminders
            </Label>
            <ToggleSwitch
              checked={isRatingReminders}
              id="rating-reminders"
              label=""
              name="rating-reminders"
              onChange={() => setRatingReminders(!isRatingReminders)}
            />
          </div>
          <div className="flex items-center justify-between py-4">
            <div className="flex grow flex-col">
              <div className="text-lg font-semibold text-black">
                Item update notifications
              </div>
              <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                Send user and product notifications for you
              </div>
            </div>
            <Label htmlFor="item-update-notifications" className="sr-only">
              Toggle item update notifications
            </Label>
            <ToggleSwitch
              checked={isItemUpdateNotifications}
              id="item-update-notifications"
              label=""
              name="item-update-notifications"
              onChange={() =>
                setItemUpdateNotifications(!isItemUpdateNotifications)
              }
            />
          </div>
          <div className="flex items-center justify-between py-4">
            <div className="flex grow flex-col">
              <div className="text-lg font-semibold text-black">
                Item comment notifications
              </div>
              <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                Send me an email when someone comments on one of my items
              </div>
            </div>
            <Label htmlFor="item-comment-notifications" className="sr-only">
              Toggle item comment notifications
            </Label>
            <ToggleSwitch
              checked={isItemCommentNotifications}
              id="item-comment-notifications"
              label=""
              name="item-comment-notifications"
              onChange={() =>
                setItemCommentNotifications(!isItemCommentNotifications)
              }
            />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div className="flex grow flex-col">
              <div className="text-lg font-semibold text-black">
                Buyer review notifications
              </div>
              <div className="text-base font-normal text-gray-500 dark:text-gray-400">
                Send me an email when someone leaves a review with their rating
              </div>
            </div>
            <Label htmlFor="buyer-review-notifications" className="sr-only">
              Toggle buyer review notifications
            </Label>
            <ToggleSwitch
              checked={isBuyerReviewNotifications}
              id="buyer-review-notifications"
              label=""
              name="buyer-review-notifications"
              onChange={() =>
                setBuyerReviewNotifications(!isBuyerReviewNotifications)
              }
            />
          </div>
        </div>
        <div className="flex ml-auto mt-6">
          <button className="px-3 ml-auto py-2 bg-secBg border border-gray-500 rounded-lg hover:bg-main transition-all hover:text-bg">
            Save All
          </button>
        </div>
      </div>
    </Card>
  );
};
 */

export default General;