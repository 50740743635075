import React from "react";

// Charts
import Chart from "react-apexcharts";

// UI Components
import { useDarkMode } from "@hooks/dark-mode";
import useAnalytics from "@hooks/analytics";

export const EmissionsByActivityType = () => {
  // Analytics
  const analytics = useAnalytics();

  const series = () => {
    const vals = Object.values(analytics.recentAnalytics)
      .map((val) => val.activityTypes)
      .flat();

    const toReturn: Record<string, number> = {};

    vals.forEach((val) => {
      if (toReturn[val.activityType]) {
        toReturn[val.activityType] += val.distance; 
      } else {
        toReturn[val.activityType] = val.distance; 
      }
    });

    const highest = Object.keys(toReturn).find(
      (val) => toReturn[val] === Math.max(...Object.values(toReturn))
    );

    return (
      (highest ?? "").charAt(0).toUpperCase() +
      (highest ?? "").slice(1).toLowerCase()
    );
  };

  return (
    <div className="rounded-lg bg-secBg p-4 shadow sm:p-6 xl:p-8">
      <div className="flex items-center">
        <div className="shrink-0">
          <span className="text-2xl font-bold leading-none text-black sm:text-3xl">
            {series()}
          </span>
          <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
            Most Utilized Transportation Method
          </h3>
        </div>
      </div>
      <ActivityTypeChart analytics={analytics} />
    </div>
  );
};

export const ActivityTypeChart: React.FC<{ analytics: ReturnType<typeof useAnalytics> }> = function ({ analytics }) {
  const { dark: isDarkTheme } = useDarkMode();

  const seriesData = () => {
    const vals = Object.values(analytics.recentAnalytics)
      .map((val) => val.activityTypes)
      .flat();

    const toReturn: Record<string, { distance: number; emissions: number }> = {};

    vals.forEach((val) => {
      if (toReturn[val.activityType]) {
        toReturn[val.activityType].emissions += val.emissions;
        toReturn[val.activityType].distance += val.distance; 
      } else {
        toReturn[val.activityType] = {
          emissions: val.emissions,
          distance: val.distance,
        };
      }
    });

    return toReturn;
  };

  const series = Object.values(seriesData()).map((val) => val.distance);
  const options: ApexCharts.ApexOptions = {
    labels: Object.keys(seriesData()),
    colors: ["#16BDCA", "#FDBA8C", "#1A56DB", "#D61F69", "#9061F9", "#6875F5"],
    chart: {
      type: "pie",
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: true,
      },
      foreColor: isDarkTheme ? "#A1A5B2" : "#4B5563",
    },
    stroke: {
      colors: [isDarkTheme ? "#111827" : "#fff"],
    },
    plotOptions: {
      pie: {
        donut: {
          size: "5%",
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.9,
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      fillSeriesColor: false,
      inverseOrder: false,
      style: {
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
      },
      x: {
        show: true,
        formatter: (val, { dataPointIndex, w }) =>
          w.config.labels[dataPointIndex],
      },
      y: {
        formatter: (value) => `${value.toFixed(2)} km`, 
      },
    },
    dataLabels: {
      enabled: false, 
    },
    legend: {
      show: true,
      formatter: function (seriesName, opts) {
        const distance = opts.w.globals.series[opts.seriesIndex]; 
        return `${seriesName}: ${distance.toFixed(2)} km`; 
      },
      position: "right", 
    },
  };
  

  return (
    <Chart
      height={305}
      options={options}
      series={series}
      type="pie"
    />
  );
};

// Funktion til at få vehicleType data
export const useVehicleTypeData = () => {
  const analytics = useAnalytics();

  const getVehicleTypeData = () => {
    const vals = Object.values(analytics.recentAnalytics)
      .map((val) => val.activityTypes)
      .flat();

    const toReturn: Record<string, { distance: number; emissions: number }> = {};

    vals.forEach((val) => {
      if (toReturn[val.activityType]) {
        toReturn[val.activityType].emissions += val.emissions;
        toReturn[val.activityType].distance += val.distance; 
      } else {
        toReturn[val.activityType] = {
          emissions: val.emissions,
          distance: val.distance, 
        };
      }
    });

    return toReturn;
  };

  return getVehicleTypeData();
};

export default EmissionsByActivityType;
