import { Routes } from "@routes";
import React from "react";
import { Link } from "react-router-dom";

// All The Features
const features = [
  "7 Days Free Test",
  "Employee App",
  "Employee Education",
  "Individual Insights",
  "Admin Panel",
  "Emission Analytics",
  "Automated Tracking",
  "Real-Time Data",
  "Compliance Ready",
  "Geofenced Locations",
  "Transportation Type Detection",
  "Vehicle Registration",
  "Works When Terminated",
  "Data Exports",
  "Employee Engagement Tools"
];

// The Split Up Features
const nestedFeatures = [
  features.slice(0, 5),
  features.slice(5, 10),
  features.slice(10, 15)
];

const Pricing = () => (
  <section id="pricing" className="bg-secBg lg:h-screen flex items-center justify-center border-b border-gray-600">
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div className="bg-bg rounded-lg shadow lg:grid lg:grid-cols-3 ">
        <div className="col-span-2 p-6 lg:p-8 lg:rounded-l-lg rounded-t-lg lg:rounded-t-none lg:border-b border-r lg:border-r-0 border-t border-l border-gray-600">
          <h2 className="mb-1 text-2xl font-bold text-black">
            Custom pricing built for all businesses!
          </h2>
          <p className="text-lg font-light text-gray-500 dark:text-gray-400">
            The price model is a SaaS based structure paid monthly or yearly by
            number of users.
          </p>
          <div className="grid gap-4 mt-4 lg:mt-6 sm:grid-cols-2 md:grid-cols-3">
            {nestedFeatures.map(arr => (
              <ul key={arr[0]} className="space-y-4 dark:text-white">
                {arr.map(val => (
                  <li key={val} className="flex space-x-2.5">
                    {/* Icon */}
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="leading-tight text-gray-500 dark:text-gray-400">
                      {val}
                    </span>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <div className="flex p-6 text-center bg-secBg border-l lg:border-l-0 lg:border-t border-b border-r border-gray-600 rounded-b-lg lg:rounded-b-none lg:rounded-r-lg lg:p-8">
          <div className="self-center flex h-full mt-10 flex-col justify-evenly content-evenly w-full">
            <div className="text-4xl mb-10 font-extrabold text-black">
              Custom Pricing
            </div>
            <div>
              <Link
                to={Routes.ContactSales.path}
                className="flex justify-center text-black bg-secBg border border-main hover:text-secBg transition-all hover:bg-main focus:ring-4 focus:ring-bue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Get Started
              </Link>
              <p className="text-gray-500 mt-2 font-light text-sm dark:text-gray-400">
                Contact Drivn to get an exact quote based on your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Pricing;
