import React from "react";

// Images
import Grass from "../../assets/images/backgrounds/grass.webp";

// Helpers
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import NoAuth from "../no-auth/no-auth";
import { useDarkMode } from "@hooks/dark-mode";
import { useUser } from "@hooks/user";

const Contact = () => {
  // Login Resolver
  const resolver = yup
    .object()
    .shape({
      email: yup.string().required("Email is required"),
      subject: yup.string().required("Subject is required"),
      message: yup.string().required("Message is required")
    })
    .required();

  // The Form Ref
  const { register, handleSubmit } = useForm<Drivn.ContactValues>({
    resolver: yupResolver(resolver)
  });

  // Submit The Form
  const submit = (event: Drivn.ContactValues) => {};

  // Dark Mode
  const dark = useDarkMode();

  // The User
  // eslint-disable-next-line
  const user = useUser(true);

  return (
    <section
      style={{
        backgroundImage: `url(${Grass})`,
        backgroundSize: "cover",
        backgroundColor: dark.dark
          ? "rgba(0, 0, 0, 0.6)"
          : "rgba(255, 255, 255, 0.8)",
        backgroundBlendMode: "multiply"
      }}
      className="bg-bg pt-20"
    >
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-white dark:text-black">
          Contact Us
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-center text-gray-300 dark:text-gray-400 sm:text-xl">
          Got a technical issue? Want to send feedback about a beta feature?
          Need details about our Business plan? Let us know.
        </p>
        <form onSubmit={handleSubmit(submit)} className="space-y-8">
          <div>
            <label className="block mb-2 text-sm font-medium text-white dark:text-black">
              Your email
            </label>
            <input
              {...register("email")}
              type="email"
              id="email"
              className="shadow-sm bg-secBg border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:shadow-sm-light"
              placeholder="name@company.com"
              required
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-white dark:text-black">
              Subject
            </label>
            <input
              {...register("subject")}
              type="text"
              id="subject"
              className="block p-3 w-full text-sm text-black bg-secBg rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:border-gray-600 dark:placeholder-gray-400 dark:shadow-sm-light"
              placeholder="The subject of your issue"
              required
            />
          </div>
          <div className="sm:col-span-2">
            <label className="block mb-2 text-sm font-medium text-white dark:text-black">
              Your message
            </label>
            <textarea
              {...register("message")}
              id="message"
              rows={6}
              className="block p-2.5 w-full text-sm text-black bg-secBg rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:border-gray-600 dark:placeholder-gray-400 "
              placeholder="The content of your issue"
            ></textarea>
          </div>
          <button
            type="submit"
            className="py-3 px-5 text-sm font-medium w-full text-center text-bg rounded-lg bg-main hover:text-black hover:bg-secBg focus:outline-none transition-all"
          >
            Send message
          </button>
        </form>
      </div>
    </section>
  );
};

export default <NoAuth component={Contact} title="Contact Support" />;
