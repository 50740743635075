import { useEffect, useState } from "react";

// Firebase Helpers
import {
  auth,
  firebaseSignOut,
  firebaseSignIn,
  onAuthChange,
  FirebaseUser
} from "../services/firebase";

// Routing
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

// Helpers
import { useApi } from "@hooks/api";
import {
  useAppDispatch,
  useAppSelector,
  logIn,
  logOut,
  Routes,
  update,
  startUpdate,
  userError,
  isErr
} from "@util";
import { toast } from "react-toastify";

/**
 * Handle User State
 */
export const useUser = (isProtected: boolean = false) => {
  // The User Sign In State
  const [signedIn, setSignedIn] = useState(false);

  // The Current User
  const { user, email, loading, error } = useAppSelector(state => state.user);

  // The Temporary Fetched User
  const [fetchedUser, setUser] = useState<Drivn.User | undefined>();

  // App Dispatch
  const dispatch = useAppDispatch();

  // The Current Firebase User
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);

  // Navigation
  const navigate = useNavigate();

  // The API
  const api = useApi();

  // The Current Location
  const location = useLocation();

  // Sign In A User
  const handleSignIn = async (
    response: Response,
    rememberMe: boolean,
    redirect: string | null
  ): Promise<void> => {
    // The User
    const user: Drivn.UserWithToken = await response.json();

    // Setting The Proper Token Persistense
    // A await setPersistence(auth, { type: rememberMe ? "SESSION" : "NONE" });

    // Sign In With Firebase
    const firebaseUser = await firebaseSignIn(auth, user.token);

    // Setting The Firebase User
    setFirebaseUser(firebaseUser.user);

    // Setting The User
    dispatch(logIn({ user, email: firebaseUser.user.email ?? undefined }), []);

    // Setting The Signed In Status
    setSignedIn(true);

    // If The User Signed In Successfully, Navigate To Dashboard
    navigate(redirect ?? Routes.Dashboard.path);
  };

  // Sign Out A User
  const signOut = async (): Promise<void> => {
    // Sign Out The User With Firebase
    await firebaseSignOut(auth);

    // Setting The Firebase User
    setFirebaseUser(null);

    // Setting The User
    dispatch(logOut());

    // Setting The Signed In Status
    setSignedIn(false);

    // Navigating
    navigate(Routes.NotLoggedIn.path);
  };

  // Update A User
  const updateUser = (newValues: Partial<Drivn.User>) => {
    // The Values To Update
    const toUpdate = newValues;

    // Setting The Toast
    const id = toast.loading("Updating user...");

    // Set Loading
    dispatch(startUpdate());

    // Making Sure The Image Is Being Updated Or Removing It
    if (toUpdate.image && toUpdate.image.length < 750) {
      // Removing The Image
      toUpdate.image = undefined;
    }

    // Making The API Request
    api
      .requestWithJSON<Drivn.User>("/user", true, "PUT", toUpdate)
      .then(([, res]) => {
        // Handling Errors
        if (isErr(res)) {
          dispatch(userError([res, id]));
          return;
        }

        // Closing The Toast
        toast.update(id, {
          render: "Updated user successfully",
          autoClose: 5000,
          type: "success",
          isLoading: false
        });

        // Setting The Redux User
        dispatch(update(res));
      })
      .catch(err => api.handleErrors(err, userError, id));
  };

  // Checking The Auth State
  useEffect(() => {
    onAuthChange(auth, logUser => {
      // Setting The Signed In Status
      setSignedIn(logUser !== null);

      // Protecting The Route If Necessary
      if (isProtected && logUser === null) {
        navigate(`${Routes.Signin.path}?redirect=${location.pathname}`);
      }

      // Set The User
      setFirebaseUser(logUser);

      // If The User Is Not Logged In Or The User Was Already Fetched, End Here
      if (
        logUser === null ||
        user !== undefined ||
        fetchedUser !== undefined ||
        loading ||
        error
      ) {
        return;
      }

      // Setting Loading
      dispatch(startUpdate());

      // Fetching The User
      api
        .requestWithJSON<Drivn.User>("/user", true)
        .then(([, res]) => {
          // TODO -  Handle Errors Here
          if (isErr(res)) {
            dispatch(userError([res, undefined]));
            return;
          }

          // Setting The User
          dispatch(logIn({ user: res, email: logUser.email ?? undefined }));
          setUser(res);
        })
        .catch(err => api.handleErrors(err, userError));
    });
  }, [
    api,
    dispatch,
    error,
    fetchedUser,
    isProtected,
    loading,
    location.pathname,
    navigate,
    user
  ]);

  return {
    signedIn,
    user: user ?? fetchedUser,
    email,
    firebaseUser,
    handleSignIn,
    signOut,
    loading,
    updateUser,
    error
  };
};
