import React from "react";

import { DTU, CBS, Microsoft ,Trefadder} from "@assets";

const Clients = () => (
  <section className="bg-secBg" id="partners">
    <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-xl">
      <h2 className="mb-8 text-3xl font-extrabold tracking-tight leading-tight text-center text-black md:mb-8 lg:mb-16 md:text-4xl">
        Our Partners
      </h2>
      <div className="space-y-4 sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-4 xl:gap-8 sm:space-y-0 md:mb-8 md:mt-12">
        <a
          href="https://www.dtu.dk/"
          target="_blank"
          rel="noreferrer"
          className="block py-12 px-8 text-center bg-bg rounded hover:bg-secBg border border-gray-300 dark:border-gray-600 transition-all"
        >
          <img src={DTU} alt="dtu" className="w-full" />
          <p className="font-light text-gray-500 mt-3.5 mb-4">
            Technical University of Denmark
          </p>
          <span className="flex justify-center items-center text-base font-medium text-primary-600 dark:text-primary-500">
            Visit website
            <svg
              className="ml-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </span>
        </a>
        {/* <a
          href="https://itu.dk/"
          target="_blank"
          rel="noreferrer"
          className="block py-12 px-8 text-center bg-bg rounded hover:bg-secBg border border-gray-300 dark:border-gray-600 transition-all"
        >
          <img src={ITU} alt="itu" className="w-full" />
          <p className="font-light mt-10 text-gray-500 mb-4">
            IT University of Copenhagen
          </p>
          <span className="flex justify-center items-center text-base font-medium text-primary-600 dark:text-primary-500">
            Visit website
            <svg
              className="ml-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </span>
        </a> */}
        <a
          href="https://www.cbs.dk/"
          target="_blank"
          rel="noreferrer"
          className="block py-12 px-8 text-center bg-bg rounded hover:bg-secBg border border-gray-300 dark:border-gray-600 transition-all"
        >
          <img src={CBS} alt="cbs" className="w-full" />
          <p className="font-light text-gray-500 mt-3.5 mb-4">
            Copenhagen Business School
          </p>
          <span className="flex justify-center items-center text-base font-medium text-primary-600 dark:text-primary-500">
            Visit website
            <svg
              className="ml-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </span>
        </a>
        <a
          href="https://www.microsoft.com/"
          target="_blank"
          rel="noreferrer"
          className="block py-12 px-8 text-center bg-bg rounded hover:bg-secBg border border-gray-300 dark:border-gray-600 transition-all"
        >
          <img src={Microsoft} alt="microsoft" className="w-full" />
          <p className="font-light text-gray-500 mt-3.5 mb-4">
            Microsoft
          </p>
          <span className="flex justify-center items-center text-base font-medium text-primary-600 dark:text-primary-500">
            Visit website
            <svg
              className="ml-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </span>
        </a>
        <a
          href="https://trefadder.dk/"
          target="_blank"
          rel="noreferrer"
          className="block py-12 px-8 text-center bg-bg rounded hover:bg-secBg border border-gray-300 dark:border-gray-600 transition-all"
        >
          <img src={Trefadder} alt="trefadder" className="w-full" />
          <p className="font-light text-gray-500 mt-3.5 mb-4">
            Trefadder
          </p>
          <span className="flex justify-center items-center text-base font-medium text-primary-600 dark:text-primary-500">
            Visit website
            <svg
              className="ml-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </span>
        </a>
      </div>
    </div>
  </section>
);

export default Clients;
