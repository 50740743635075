import React, { useEffect, useState } from "react";

// Hooks
import useOrganization from "@hooks/organization";
import { Card } from "flowbite-react";

const OrganizationInfo = () => {
  // The Organization
  const organization = useOrganization();

  // The Temporary User Object
  const [tempOrg, setTempOrg] = useState<Drivn.Organization | undefined>(
    organization.organization
  );

  // Setting The Temp Org If Necessary
  useEffect(() => {
    if (tempOrg === undefined && organization.organization !== undefined) {
      setTempOrg(organization.organization);
    }
  }, [tempOrg, organization]);

  return (
    <section>
      <Card className="!bg-secBg m-10">
        <h3 className="mb-4 text-xl font-bold text-black">
          Organization information
        </h3>
        <form action="#">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
              <div>
                <label className="block mb-2 text-sm font-medium text-black">
                  Organization Name
                </label>
                <input
                  type="text"
                  name="org-name"
                  id="org-name"
                  className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                  placeholder="Organization Name"
                  required={false}
                  value={tempOrg?.name ?? ""}
                  onChange={event =>
                    setTempOrg({ ...tempOrg!, name: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
              <div>
                <label className="block mb-2 text-sm font-medium text-black">
                  Registration Number
                </label>
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                  placeholder="Registration Number"
                  required={false}
                  value={tempOrg?.registrationNo ?? ""}
                  onChange={event =>
                    setTempOrg({
                      ...tempOrg!,
                      registrationNo: event.target.value
                    })
                  }
                />
              </div>
            </div>
            <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
              <div>
                <label className="block mb-2 text-sm font-medium text-black">
                  Contact Email
                </label>
                <input
                  type="text"
                  className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                  placeholder="name@company.com"
                  required={true}
                  value={tempOrg?.contactEmail ?? ""}
                  onChange={event =>
                    setTempOrg({ ...tempOrg!, contactEmail: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
              <div>
                <label className="block mb-2 text-sm font-medium text-black">
                  Email Suffix
                </label>
                <input
                  type="text"
                  className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                  placeholder="@company.com"
                  required={false}
                  value={tempOrg?.emailSuffix ?? ""}
                  onChange={event =>
                    setTempOrg({
                      ...tempOrg!,
                      emailSuffix: event.target.value
                    })
                  }
                />
              </div>
            </div>
            <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
              <div>
                <label className="block mb-2 text-sm font-medium text-black">
                  Employee Count
                </label>
                <input
                  type="number"
                  className="bg-secBg border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400"
                  placeholder=""
                  required={true}
                  value={tempOrg?.employeeCount ?? 0}
                  onChange={event =>
                    setTempOrg({
                      ...tempOrg!,
                      employeeCount: parseInt(event.target.value)
                    })
                  }
                />
              </div>
            </div>
            <div className="col-span-6 ml-auto">
              <button
                disabled={organization.loading}
                onClick={() =>
                  tempOrg && organization.updateOrganization(tempOrg)
                }
                className="px-3 py-2 bg-secBg cursor-not-allowed enabled:cursor-pointer border border-gray-300 hover:border-main dark:border-gray-500 rounded-lg opacity-50 enabled:opacity-100 enabled:hover:bg-main transition-all enabled:hover:text-bg"
              >
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </Card>
    </section>
  );
};

// Exporting The Hook
export default OrganizationInfo;
