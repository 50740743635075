import React, { useCallback } from "react";

// TS Particles
import { Engine, Options, RecursivePartial } from "tsparticles-engine";
import { loadSlim } from "tsparticles-slim";
import Particles from "react-particles";

// Assets
import { TwoPhoneImage } from "@assets";
import { useDarkMode } from "@hooks/dark-mode";

function scrollToSection(id: string){
  const element = document.getElementById(id);
  if (element){
    element.scrollIntoView({ behavior: "smooth" });
  }
}

// The First View Of The Application
const Main = () => {
  // Starting Up The Particles
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);

  // Dark Mode
  const darkMode = useDarkMode();

  // The TS Particles Options
  const options: RecursivePartial<Options> = {
    background: {
      color: {
        value: darkMode.dark ? "#28282B" : "#F0F0F0"
      }
    },
    fpsLimit: 120,
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push"
        },
        onHover: {
          enable: true,
          mode: "repulse"
        },
        resize: {
          enable: true
        }
      },
      modes: {
        push: {
          quantity: 4
        },
        repulse: {
          distance: 150,
          duration: 0.5
        }
      }
    },
    fullScreen: {
      enable: false
    },
    particles: {
      color: {
        value: darkMode.dark ? "#80ABF9" : "#1673FF"
      },
      links: {
        color: darkMode.dark ? "#80ABF9" : "#1673FF",
        distance: 200,
        enable: true,
        opacity: 1,
        width: 2
      },
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "bounce"
        },
        random: true,
        speed: 6,
        straight: false
      },
      number: {
        density: {
          enable: true,
          area: 800
        },
        value: 100
      },
      opacity: {
        value: 0.5
      },
      shape: {
        type: "square"
      },
      size: {
        value: { min: 1, max: 5 }
      }
    },
    detectRetina: true
  };

  return (
    <section className="h-screen relative w-screen flex justify-center overflow-hidden items-center border-b border-gray-600">
      <Particles
        id="tsparticles"
        options={options}
        init={particlesInit}
        className="z-0 h-screen w-screen blur-lg"
      />
      <div className="grid absolute max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 z-10">
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1 className="max-w-2xl mb-4 text-2xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-4xl">
            The revolutionised way of tracking CO2 emissions for businesses
          </h1>
          <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Say goodbye to inconsistent and low-quality data from employee
            surveys and welcome fully automatic, accurate and real-time data for
            employee transportation.
          </p>
          <button
            onClick={() => scrollToSection("pricing")}
            className="inline-flex cursor-pointer transition-all items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-full bg-main hover:bg-secBg hover:text-black focus:ring-4 focus:ring-primary-300 scroll-link"
          >
            Get started
            <svg
              className="w-5 h-5 ml-2 -mr-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <button
            onClick={() => scrollToSection("overview")}
            className="inline-flex cursor-pointer items-center justify-center px-5 py-3 text-base font-medium text-center text-black border border-black rounded-full hover:text-bg hover:bg-black scroll-link"
          >
            Learn More
          </button>
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
          <img alt="2-phones" src={TwoPhoneImage} className="scale-[2]" />
        </div>
      </div>
    </section>
  );
};

// Exporting
export default Main;
