import React, { useEffect, useState } from "react";

// Routing Components
import { useNavigate } from "react-router-dom";

// Backend Components
import NoAuth from "./no-auth";

// Form
import * as yup from "yup";
import { FieldPath, Form, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// UI Components
import { Spinner } from "@nextui-org/react";
import { AltTesla } from "@assets";

// Helpers
import { useDarkMode } from "@hooks/dark-mode";
import { backend } from "@hooks/api";
import { Err, Routes } from "@util";

const ForgotPassword = () => {
  // The History Routing Component
  const navigate = useNavigate();

  // Login Resolver
  const resolver = yup
    .object()
    .shape({
      email: yup.string().required("Email is required")
    })
    .required();

  // Show The Success Message
  const [showSuccess, setShowSuccess] = useState(false);

  // The Form Ref
  const {
    register,
    setError,
    control,
    formState: { errors, isSubmitting },
    clearErrors
  } = useForm<Drivn.ResetPasswordValues>({ resolver: yupResolver(resolver) });

  // Dark Mode
  const dark = useDarkMode();

  // Removing Root Errors 3 Seconds After Showing
  useEffect(() => {
    setTimeout(() => {
      clearErrors("root");
    }, 2000);
  }, [errors.root, clearErrors]);

  return (
    <section
      style={{
        backgroundImage: `url(${AltTesla})`,
        backgroundSize: "cover",
        backgroundColor: dark.dark
          ? "rgba(0, 0, 0, 0.6)"
          : "rgba(255, 255, 255, 0.6)",
        backgroundBlendMode: "multiply"
      }}
      className="bg-bg h-screen"
    >
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <img className="w-30 h-12 mr-2 mb-2" src={dark.getImage()} alt="logo" />
        <div className="w-full bg-bg rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:border-gray-700 relative">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div>
              <h1 className="text-xl font-bold leading-tight tracking-tight text-black md:text-2xl">
                Reset your password
              </h1>
              <h5 className="text-medium font-light mt-1 leading-tight text-black">
                Enter your email here and we'll send you an email where you can
                reset your password.
              </h5>
            </div>
            <Form
              encType="application/json"
              control={control}
              onSuccess={() => setShowSuccess(true)}
              action={backend("/user/reset")}
              onError={async ({ response }) => {
                // Formatting The Response
                const res: Err = await response?.json();

                // Setting The Error
                setError(
                  (res.relatedVar as FieldPath<Drivn.ResetPasswordValues>) ??
                    "root",
                  res,
                  { shouldFocus: true }
                );
              }}
              className="space-y-4 md:space-y-6"
              validateStatus={status => status === 200}
            >
              <div>
                <label className="block mb-2 text-sm text-black">
                  Your email
                </label>
                <input
                  {...register("email")}
                  type="email"
                  name="email"
                  id="email"
                  className={`${
                    errors.email
                      ? "border-[rgb(239,68,68,0.7)]"
                      : "border-gray-300 dark:border-gray-600"
                  } transition-all bg-secBg focus:outline-none border text-black sm:text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400`}
                  placeholder="name@company.com"
                  required={false}
                />
                <p className="pt-1 text-sm text-red-500 transition-all">
                  {errors.email?.message ?? ""}
                </p>
              </div>
              <button
                type="submit"
                className="w-full text-black border border-main hover:bg-main hover:text-bg transition-all focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 py-2.5 text-center dark:focus:ring-primary-800"
              >
                Reset Password
              </button>
            </Form>
          </div>
          <div
            className={`absolute rounded-lg bg-[rgba(255,255,255,0.68)] ${
              isSubmitting ? "opacity-100" : "opacity-0 pointer-events-none"
            } dark:bg-[rgba(0,0,0,0.68)] z-20 top-0 flex transition-all items-center justify-center w-full h-full`}
          >
            <Spinner label="Sending Password Reset..." />
          </div>
          <div
            className={`absolute rounded-lg bg-[rgba(255,255,255,0.68)] ${
              errors.root ? "opacity-100" : "opacity-0 pointer-events-none"
            } dark:bg-[rgba(0,0,0,0.68)] z-20 top-0 flex flex-col transition-all items-center justify-center w-full h-full`}
          >
            <p className="text-lg text-red-500 font-semibold transition-all">
              {errors.root?.message ?? "An Error Occured"}
            </p>
            <button
              className="mt-5 px-3 py-2 border border-main hover:bg-main hover:text-bg rounded-lg"
              onClick={() => clearErrors("root")}
            >
              Try Again
            </button>
          </div>
          <div
            className={`absolute rounded-lg bg-[rgba(255,255,255,0.68)] ${
              showSuccess ? "opacity-100" : "opacity-0 pointer-events-none"
            } dark:bg-[rgba(0,0,0,0.68)] z-20 top-0 flex flex-col transition-all items-center justify-center w-full h-full`}
          >
            <p className="text-lg text-black font-semibold transition-all">
              Check your email! (And Spam)
            </p>
            <button
              className="mt-5 px-3 py-2 border border-main hover:bg-main hover:text-bg transition-all rounded-lg"
              onClick={() => navigate(Routes.Signin.path)}
            >
              Go To Log In
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

// Exporting
export default <NoAuth component={ForgotPassword} title="Forgot Password" />;
