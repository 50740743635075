import { parseAbsoluteToLocal } from "@internationalized/date";

class DateRangeHelper {
    /**
     * Returns a default date range from the current time to 4 days ago.
     */
    static getDefaultDateRange() {
        const currentDate = new Date();
        const startDate = new Date(currentDate);

        startDate.setDate(currentDate.getDate() - 4);
        startDate.setHours(0, 1, 0, 0);
        currentDate.setHours(23, 59, 59, 999);

        return {
        start: parseAbsoluteToLocal(startDate.toISOString()),
        end: parseAbsoluteToLocal(currentDate.toISOString()),
        };
    }

    /**
     * Returns a [start, end] range based on 'week' | 'month' | 'year'.
     * If no match, returns today's start and end time.
     */
    static getRangeByOption(selectedValue: string) {
        const currentDate = new Date();
        let startDate = new Date(currentDate);
        let dayOfWeek = currentDate.getDay();
        dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

        switch (selectedValue) {
        case "week":
            startDate.setDate(currentDate.getDate() - dayOfWeek);
            break;
        case "month":
            startDate.setDate(1);
            break;
        case "year":
            startDate.setMonth(0);
            startDate.setDate(1);
            break;
        default:
            startDate = new Date();
        }

        startDate.setHours(0, 0, 0, 0);
        currentDate.setHours(23, 59, 59, 999);

        return {
        start: parseAbsoluteToLocal(startDate.toISOString()),
        end: parseAbsoluteToLocal(currentDate.toISOString()),
        };
    }
}

export default DateRangeHelper;
  