import useAnalytics from "@hooks/analytics";
import { useDarkMode } from "@hooks/dark-mode";
import Chart from "react-apexcharts";
import React from "react";

/**
 * Component that shows the number of commutes.
 */
const CommuteChartComponent: 
    React.FC<{analytics: ReturnType<typeof useAnalytics>; }> = ({ analytics }) => {
    return (
      <div className="rounded-lg bg-secBg p-4 shadow sm:p-6 xl:p-8">
        <div className="flex items-center">
          <div className="shrink-0">
            <span className="text-2xl font-bold leading-none text-black sm:text-3xl">
              {Object.values(analytics.recentAnalytics)
                .map((rec) => rec.tripIds)
                .flat().length}
            </span>
            <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
              Amount of commutes
            </h3>
          </div>
        </div>
        <CommuteChart analytics={analytics} />
      </div>
    );
  };
  
  /**
   * Component containing the bar chart, using ApexCharts.
   */
  const CommuteChart: React.FC<{
    analytics: ReturnType<typeof useAnalytics>;
  }> = ({ analytics }) => {
    const { dark: isDarkTheme, primary } = useDarkMode();
  
    const backgroundBarColors = isDarkTheme
      ? Array(7).fill("#374151")
      : Array(7).fill("#E5E7EB");
  
    const options: ApexCharts.ApexOptions = {
      labels: Object.keys(analytics.recentAnalytics),
      chart: {
        foreColor: "#4B5563",
        fontFamily: "Inter, sans-serif",
        toolbar: { show: true },
      },
      theme: {
        monochrome: {
          enabled: true,
          color: primary,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "25%",
          borderRadius: 3,
          colors: {
            backgroundBarColors,
            backgroundBarRadius: 3,
          },
        },
      },
      xaxis: {
        floating: true,
        labels: { show: false },
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      tooltip: {
        hideEmptySeries: false,
        shared: true,
        intersect: false,
        style: { fontSize: "14px", fontFamily: "Inter, sans-serif" },
      },
      states: {
        hover: {
          filter: { type: "darken", value: 0.8 },
        },
      },
      fill: { opacity: 1 },
      yaxis: { show: false },
      grid: { show: false },
      dataLabels: { enabled: false },
      legend: { show: true },
    };
  
    const series = [
      {
        name: "Commutes",
        data: Object.values(analytics.recentAnalytics).map(
          (val) => val.tripIds.length
        ),
      },
    ];
  
    return <Chart height={305} options={options} series={series} type="bar" />;
  };

export default CommuteChartComponent;