import React from "react";

// Icon Components
import { Routes } from "../../util/routes";

// Helpers
import { useDarkMode } from "@hooks/dark-mode";
import { NavLink } from "react-router-dom";

export default function Footer() {
  // The Dark Mode Status
  const dark = useDarkMode();

  return (
    <footer className="p-4 z-50 absolute w-full sm:p-6 bg-bg border-t border-gray-600">
      <div className="mx-auto max-w-screen-xl">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="https://drivn.dk" className="flex items-center">
              <img
                src={dark.getImage()}
                className="mr-3 h-8"
                alt="Drivn Logo"
              />
            </a>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-8 sm:gap-6">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-black uppercase">
                About Us
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="mb-4">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/drivn3"
                    className="hover:underline text-main hover:text-black"
                  >
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    href={Routes.FAQ.path}
                    className="hover:underline text-main hover:text-black"
                  >
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-black uppercase">
                Resources
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="mb-4">
                  <a
                    href={Routes.Contact.path}
                    className="hover:underline text-main hover:text-black"
                  >
                    Customer Support
                  </a>
                </li>
                <li>
                  <a
                    href={Routes.ContactSales.path}
                    className="hover:underline text-main hover:text-black"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold uppercase text-black">
                Legal
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="mb-4">
                  <NavLink
                    to={Routes.PrivacyPolicy.path}
                    className="hover:underline text-main hover:text-black"
                  >
                    Privacy Policy
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={Routes.TermsAndConditions.path}
                    className="hover:underline text-main hover:text-black"
                  >
                    Terms &amp; Conditions
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2024{" "}
            <a href="https://drivn.dk" className="hover:underline">
              Drivn ApS
            </a>
            . All Rights Reserved.
          </span>
          <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            <a
              href="https://www.linkedin.com/company/drivn3"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 hover:text-main"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 15 15"
              >
                <path
                  fillRule="evenodd"
                  d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  clipRule="evenodd"
                />
                <path d="M3 5.012H0V15h3V5.012Z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
