import React from "react";

// Routing
import { Link } from "react-router-dom";
import { Routes } from "@routes";

// Hooks
import useOrganization from "@hooks/organization";

// Assets
import { UserAtDesk } from "@assets";


const NotAdmin = ({ user }: { user: Drivn.User }) => {
  // The Organization
  const org = useOrganization();

  return (
    <section className="w-full min-h-[calc(100vh-110px)] mt-[110px] flex flex-col justify-center items-center">
      <img src={UserAtDesk} alt="user-at-desk" />

      <h1 className="font-bold text-4xl mb-5">
        This page is only accessible to admins
      </h1>
      <p className="mb-20 text-center">
        Hi {user.firstName}, at the moment this page is accessible to admin
        users. If you should have access to your organization's analytics, <br />
        contact your organization administrator at{" "}
        <a
          href={`mailto:${org.organization?.contactEmail}`}
          className="text-main underline"
        >
          {org.organization?.contactEmail}
        </a>
        . If the issue persists,{" "}
        <Link
          to={Routes.Contact.path}
          className="text-main underline"
        >
          contact support
        </Link>.
      </p>
    </section>
  );
};

export default NotAdmin;
