// Defaul Components
import React from "react";

// Routing Components
import { Routes } from "../../util/routes";
import { Link } from "react-router-dom";

import NoAuth from "../no-auth/no-auth";

/**
 * The View To Present When The User Attemps To Route To A Page That Doesn't Exist
 *
 * @returns A React Compnent Containing The `NotFound` View
 */
const NotFound = () => (
  <section className="h-screen bg-bg flex items-center justify-center">
    <div className="text-center pt-10">
      <p className="text-base font-semibold text-main">404</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-black sm:text-5xl">
        Page not found
      </h1>
      <p className="mt-6 text-base leading-7 text-gray-600">
        Sorry, we couldn’t find the page you’re looking for.
      </p>
      <div className="mt-5 flex items-center justify-center gap-x-6">
        <Link
          to={Routes.NotLoggedIn.path}
          className="rounded-full bg-main px-3.5 py-2.5 text-sm font-semibold text-white shadow-md hover:text-main hover:bg-bg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main-600"
        >
          Go back home
        </Link>
        <Link
          className="text-sm font-semibold text-black hover:text-main"
          to={Routes.Contact.path}
        >
          Contact support <span aria-hidden="true">&rarr;</span>
        </Link>
      </div>
    </div>
  </section>
);

// Exporting The View
export default <NoAuth component={NotFound} title="404" />;
